.promo-main-container {
  /* height: 630px; */
  padding: 72px;
  background-color: #d8d8d8;
  /* margin-top: 400px; */
  background: url("https://firebasestorage.googleapis.com/v0/b/nishad-web-a022a.appspot.com/o/nishad-web-assets%2Fback-img-icons.png?alt=media&token=7f31a221-436e-4102-8435-7cbf1661252e");
  background-size: cover;
  position: relative;
}

.promo-sub-main-container {
  border: solid 1px #979797;
  /* position: absolute; */
  background-color: #ffffff;
  /* top: 12%;
    left: 4%;
    right: 4%;
    bottom: 12%; */
  display: flex;
  padding: 2% 0;
  justify-content: center;
}

.promo-img-container {
  width: 286px;
  height: 286px;
  margin: 6% 10%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.promo-img-container img {
  width: 100%;
  height: 100%;
  background-size: cover;
}

.promo-text-container {
  width: 360px;
  margin: 6% 10%;
  display: flex;
  align-items: center;
}

.promo-text-container p {
  font-family: cursive;
  font-size: 25px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #050505;
  text-align: justify;
  margin-top: 4px;
}

@media only screen and (max-width: 600px) {
  .promo-main-container {
    /* height: 630px; */
    padding: 48px;
  }

  .promo-img-container {
    width: 234px;
    height: 234px;
    margin: 12% 10%;
  }
  .promo-text-container {
    margin: 8% 12%;
  }
  .promo-text-container p {
    font-size: 21px;
  }
}
