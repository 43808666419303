.explore-main-container {
    min-height: 90vh;
    position: relative;
    display: grid;
}

.explore-p-container {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
}

.explore-p-container p {
    font-family: TrebuchetMS;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #393939;
    margin-bottom: 24px;
}

.explore-explore-button-container {
    display: flex;
    justify-content: center;
}

.explore-explore-button {
    border-radius: 4px;
    border: solid 1px #f42121;
    font-family: TrebuchetMS;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #f42121;
    padding: 8px 16px;
    cursor: pointer;
    display: inline;
}

.explore-img-container {
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: 0;
    right: 0;
    display: flex;
    justify-content: center;
}

.explore-img-container img {
    width: 100%;
    background-size:contain; 
}



@media only screen and (max-width: 600px) {
    .explore-main-container{
        height: 85vh;
    }
    .explore-p-container {
        width: 100%;
        top:45%;
    }
    .explore-p-container p{
        text-align: center;
        font-size: 22px;
    }
    .explore-explore-button {
        font-size: 16px;
    }
}