/* https://skyjetairtravel.co.uk/wp-content/uploads/2017/01/Mumbai.jpg */

body {
    margin: 0px;
}

.app-footer {
    padding: 14px 3px 24px 120px;
    color: #000;
    width: 100%;
    background-color: #e0e0e0;
    margin-left: 0;
}
.app-footer-register{
    margin-top:48px; 
}

.app-footer-sub-container {
    margin-top: 36px;
}

.app-footer-container {
    margin-top: 20px;
    color: #000;
}

.app-footer-sub-content-container {
    margin-top: 12px;
    cursor: pointer;
}
.app-footer-container{
    margin-top: 2px;
}

.bottom-menu-link {
    color: #000;
}

.active {
    color: #f42121;
    font-weight: 600;
}

.app-footer-social-icon-anchor-container {
    margin-left: 20px;
}

h5{
    margin-bottom: 16px; 
}


@media only screen and (max-width: 600px) {
    .app-footer{

    padding: 14px 3px 24px 32px;
    }
}