.scroller-main-container{
    height: 96vh;
}

.parallax{

  /* The image used */
  background-image: url("https://firebasestorage.googleapis.com/v0/b/nishad-static-web.appspot.com/o/nishad-web-assets%2FFamily-photo-ideas-Photojaanic-16.png?alt=media&token=27644a65-d79b-458f-b5be-e81848ba4a17");

  /* Full height */
  height: 100%; 

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}


.scroller-text-container{
    width: 368px;
    height: 178px;
    opacity: 0.85;
    border-radius: 4px;
    background-color: #f42121;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 40vh);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.scroller-text-container p{
    width: 254px;
    font-family: TrebuchetMS;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #f9f3f3;
    text-align: center;
    margin-top: 14px;
}