.products-main-container {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.products-text-container {
  display: flex;
  justify-content: center;
  padding-top: 120px;
}

.products-text-container p {
  font-family: TrebuchetMS;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.slider-default-container {
  overflow: scroll;
  padding: 140px 8% 220px;
  width: 100%;
  display: -webkit-box;
  text-align: -webkit-center;
}

.slider-container {
  /* position: relative; */
  display: inline-flex;
  justify-content: space-evenly;
  width: 100%;
  max-width: 100%;
  /* overflow: hidden; */
}

/* slider container */
.slider-main-container {
  /* position: relative; */
  /* margin-top: 2%; */
  display: inline-block;
  justify-content: space-around;
  align-items: center;
  background: white;
  border-radius: 4px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.slider-main-container:hover {
  box-shadow: 0px 0px 150px #000000;
  z-index: 2;
  -webkit-transition: all 200ms ease-in;
  -webkit-transform: scale(1.5);
  -ms-transition: all 200ms ease-in;
  -ms-transform: scale(1.5);
  -moz-transition: all 200ms ease-in;
  -moz-transform: scale(1.5);
  transition: all 200ms ease-in;
  transform: scale(1.5);
}

.slider-img-container {
  height: 220px;
  width: 220px;
  margin: 24px;
}

.slider-text-container p {
  font-family: TrebuchetMS;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #050505;
  text-align: center;
  margin-bottom: 48px;
}

@media only screen and (max-width: 600px) {
  .products-main-container {
    padding-left: 0;
    padding-right: 0;
    height: 98vh;
  }

  .products-text-container p {
    font-size: 22px;
    font-weight: bold;
  }
}
