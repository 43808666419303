.nav-bar-items {
  justify-content: flex-end;
  padding-right: 70px;
}

.nav-active-item {
  font-weight: 500 !important;
  color: #000000 !important;
  font-family: Helvetica !important;
}

.nav-menu-link {
  margin: 16px 16px;
  font-family: Helvetica;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  cursor: pointer;
}

.navbar-back-color {
  background: white !important;
  box-shadow: 0 1px 1px 0 rgba(204, 204, 204, 0.5);
}

.navbar-image-container {
  position: relative;
}

.navbar-logo-container {
  position: absolute;
  left: 80px;
  top: -28px;
  text-align: center;
  width: 180px;
  height: 185px;
  border: solid 1px #c09a9a;
  background-color: #f42121;
  z-index: 8;
  border-radius: 1px;
}

.navbar-prod-container {
  display: inline-block;
  margin-top: 14px;
  margin-bottom: 14px;
}

.navbar-prod-icon {
  width: 40px;
  height: 40px;
}

.navbar-prod-name {
  font-family: TrebuchetMS;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #f9f3f3;
  margin-top: -10px;
}

.navbar-brand-container {
  text-align: -webkit-center;
}

.navbar-brand-name {
  font-family: TrebuchetMS;
  font-size: 38px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.navbar-brand-tagline {
  font-family: TrebuchetMS;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #f9f3f3;
  margin-top: -14px;
}

@media only screen and (max-width: 600px) {
  .navbar-logo-container {
    top: -16px;
    text-align: center;
    width: 140px;
    height: 148px;
    left: 0;
  }
  .navbar-prod-container {
    margin-top: 8px;
    margin-bottom: 6px;
  }
  .navbar-brand-name {
    font-size: 32px;
  }
  .navbar-brand-tagline {
    font-size: 12px;
  }
  .navbar-prod-icon{

  width: 36px;
  height: 36px;
  }
  .navbar-prod-name{
      font-size:18px 
  }
}
