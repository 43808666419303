.support-main-container {
  padding: 85px 65px;
  /* background: #ffffff; */
}

.support-tagline-content-container {
  margin-top: 20px;
  margin-bottom: 85px;
  text-align: center;
}
.support-tagline-content-container p {
  font-family: TrebuchetMS;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.support-main-content-container {
  margin-top: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 120px;
}

.support-content-container {
  background-color: #e0e0e0;
  padding: 60px 0;
  text-align: center;
  border-radius: 4px;
  width: 400px;
  margin-top: 20px;
}

.support-content-container-ptag {
  margin-top: 20px;
  color: cadetblue;
}
@media only screen and (max-width: 600px) {
  .support-main-content-container {
    margin-bottom: 60px;
  }
  .support-tagline-content-container p {
    font-size: 22px;
  }
}
